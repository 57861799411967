import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import CurrentUserReducer from "./CurrentUserReducer";
import ProjectReducer from "./ProjectReducer";
import TenantReducer from "./TenantReducer";
import TravelReducer from "./TravelReducer";
import ExpenseAccountReducer from "./ExpenseAccountReducer";
import AdvancePaymentReducer from "./AdvancePaymentReducer";
import UserReducer from "./UserReducer";
import SpendingReducer from "./SpendingReducer";
import HistoricalReducer from "./HistoricalReducer";

// Permite unificar la parte del estado de la que cada reducer se encarga
const rootReducer = combineReducers({
  currentUser: CurrentUserReducer,
  tenant: TenantReducer,
  project: ProjectReducer,
  travel: TravelReducer,
  expense_account: ExpenseAccountReducer,
  user: UserReducer,
  advance_payment: AdvancePaymentReducer,
  spending: SpendingReducer,
  historical: HistoricalReducer,
  form: formReducer,
});

export default rootReducer;
