import swal from "sweetalert2";
import { forceLogOut } from "./authenticationActions";

let dict_options_env = {
  development: {
    port: 3000,
    front_port: 8080,
    root_url: "http://localhost",
    login: {
      gmail:
        "https://accounts.google.com/o/oauth2/v2/auth?client_id=886539794026-psnpe7ik4iqggv24fo1bkb46n37uaej3.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fusers%2Flogin&response_type=code&scope=openid%20email%20profile&state=google&prompt=select_account",
      outlook:
        "https://login.microsoftonline.com/uniandes.edu.co/oauth2/v2.0/authorize?client_id=de33f5fd-6510-46f0-8c67-1dceadacc10f&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fusers%2Flogin&response_type=code&scope=openid+profile&state=microsoft",
    },
    blob: {
      url: "https://cevservidorpruebasdiag.blob.core.windows.net/",
      token:
        "?sp=racwd&st=2023-10-02T18:51:02Z&se=2024-10-03T02:51:02Z&spr=https&sv=2022-11-02&sr=c&sig=5BW06h6EPQ9tCsLsweHzXj1SXDKBhRCS2eN0ek5P3qg%3D",
      container_name: "gastos-lego-test",
    },
  },
  test: {
    port: 3006,
    front_port: 8084,
    root_url: "https://centrodeevaluacion.myftp.org",
    login: {
      gmail:
        "https://accounts.google.com/o/oauth2/v2/auth?client_id=886539794026-psnpe7ik4iqggv24fo1bkb46n37uaej3.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcentrodeevaluacion.myftp.org%3A8084%2Fusers%2Flogin&response_type=code&scope=openid%20email%20profile&state=google&prompt=select_account",
      outlook:
        "https://login.microsoftonline.com/uniandes.edu.co/oauth2/v2.0/authorize?client_id=de33f5fd-6510-46f0-8c67-1dceadacc10f&redirect_uri=https%3A%2F%2Fcentrodeevaluacion.myftp.org%3A8084%2Fusers%2Flogin&response_type=code&scope=openid+profile&state=microsoft",
    },
    blob: {
      url: "https://cevservidorpruebasdiag.blob.core.windows.net/",
      token:
        "?sp=racwd&st=2023-10-02T18:51:02Z&se=2024-10-03T02:51:02Z&spr=https&sv=2022-11-02&sr=c&sig=5BW06h6EPQ9tCsLsweHzXj1SXDKBhRCS2eN0ek5P3qg%3D",
      container_name: "gastos-lego-test",
    },
  },
  production: {
    port: 3001,
    front_port: "",
    root_url: "https://legalizagastoscev.uniandes.edu.co",
    login: {
      gmail:
        "https://accounts.google.com/o/oauth2/v2/auth?client_id=886539794026-psnpe7ik4iqggv24fo1bkb46n37uaej3.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Flegalizagastoscev.uniandes.edu.co/users/login&response_type=code&scope=openid%20email%20profile&state=google&prompt=select_account",
      outlook:
        "https://login.microsoftonline.com/uniandes.edu.co/oauth2/v2.0/authorize?client_id=de33f5fd-6510-46f0-8c67-1dceadacc10f&redirect_uri=https%3A%2F%2Flegalizagastoscev.uniandes.edu.co/users/login&response_type=code&scope=openid+profile&state=microsoft",
    },
    blob: {
      url: "https://cevlegostorageaccount.blob.core.windows.net/",
      token:
        "?sp=racwdl&st=2024-10-23T14:12:15Z&se=2025-10-18T22:12:15Z&sv=2022-11-02&sr=c&sig=4k05OB7KBlO7oc8yqu1wYEEP%2Fo7EA7%2FPkYHTsc%2F2avc%3D",
      container_name: "gastos-lego-production",
    },
  },
};

let env = dict_options_env[process.env.REACT_APP_APP_ENV];

// URL base backend
export const ROOT_URL = `${env.root_url}:${env.port}`;

// URL base del frontend
export const hostname = window.location.host;

// Protocolo del host
export const host_protocol = window.location.protocol;

// URLs login gmail and outlook
export const LOG_IN_URL_OUTLOOK = env.login.outlook;
export const LOG_IN_URL_GMAIL = env.login.gmail;

// URL redirecting auth services.
export const REDIRECT_URI = process.env.REACT_APP_APP_ENV === 'production' ? `${env.root_url}/users/login` : `${env.root_url}:${env.front_port}/users/login`;

export const BLOB_STORAGE_URL = env.blob.url;

// tokens SAS del blob service
export const AZURE_STORAGE_CONNECTION_STRING = `${env.blob.url}${env.blob.token}`;

// container name blob azure"
export const AZURE_STORAGE_FILE_CONTAINER = env.blob.container_name;

//export const SHOW_MULTIMEDIA_RECORDERS = process.env.NODE_ENV === 'production' ? false : true;
export const SHOW_MULTIMEDIA_RECORDERS = true;

// Código de petición con estado Prohibido.
export const FORBIDDEN_STATUS_CODE = 403;

// Código de petición con estado Entidad no procesable.
export const UNPROCESSABLE_ENTITY_STATUS_CODE = 422;

// Código de petición con estado No autorizado.
export const UNAUTHORIZED_STATUS_CODE = 401;

// Código de petición con estado Solicitud incorrecta
export const BAD_REQUEST_STATUS_CODE = 400;

// URL a información de la página principal.
export const MAIN_TEXT = `${ROOT_URL}/TextoPrincipal.html`;

// Extensión para manejo de respuestas (elementos) sin adjunto
export const NO_FILE_EXTENSION = "no";

// Extensiones de adjuntos aceptadas para manejo de embebidos
export const ACCEPTED_IMAGES_FORMATS = [
  "pdf",
  "png",
  "jpg",
  "jpeg",
  "gif",
  "tif",
  "tiff",
];

// Cantidad de elementos para paginación
export const PAGINATION = "?page%5Bnumber%5D=1&page%5Bsize%5D=10";

// Cantidad de elementos para paginación de reportes
export const REPORT_PAGINATION = "?page%5Bnumber%5D=1&page%5Bsize%5D=1";

/**
 * Manejador de errores de autorización.
 *
 * @export isUnauthorizedError
 * @param {*} error : Información del error a manejar.
 * @param {*} dispatch : Función para manejo de
 * @returns Si hubo o no error asociado a autorización.
 */
export function isUnauthorizedError(error, dispatch) {
  if (error.request.status === UNAUTHORIZED_STATUS_CODE) {
    swal(
      "No recordamos quién eres",
      "Por favor vuelve a iniciar sesión para poder realizar esta acción",
      "error"
    );
    forceLogOut(dispatch);
    return true;
  }
  return false;
}

/**
 * Manejo de cabeceras de autenticación. La información se obtiene del
 * LocalStorage del navegador (token de autenticación e email).
 *
 * @export getAuthHeaders
 * @returns Objeto con los headers de autenticación asociados al usuario actual.
 */
export function getAuthHeaders() {
  return {
    headers: {
      "X-User-Token": sessionStorage.getItem("authenticationToken"),
      "X-User-Email": sessionStorage.getItem("email"),
    },
  };
}
