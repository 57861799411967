import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert2";

/**
 * Componente wrapper para evitar el renderizado de componentes/rutas
 * accesibles solo por usuarios con rol Admin
 *
 * @export
 * @param {*} ComposedComponent
 * @returns
 */
export default function (ComposedComponent) {
  class RequireAdmin extends Component {
    componentWillMount() {
      const { currentUser } = this.props;
      if (!currentUser || !(currentUser.preferredRole == "admin")) {
        if (!currentUser || !currentUser.isExamAdmin) {
          this.props.history.push("/");
          swal("Lo sentimos...", "No tienes acceso esta página.", "error");
        }
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { currentUser: state.currentUser };
  }

  return connect(mapStateToProps)(RequireAdmin);
}
