
// Actualiza todas las preguntas en el estado de la aplicación.
export const SET_TENANT = 'SET_TENANT';

var getTenant = function (){
    let current_tenant
    var pathArray = window.location.pathname.split('/')
    let hostname = window.location.hostname.toLowerCase().split(".")

    if (pathArray[1] !== "" && pathArray[1] === "ecentroevaluacion" ){//captura centrodeevaluacion.myftp.org/ecentroevaluacion        
        current_tenant = "ecentroevaluacion"
    }else if(hostname[0] === "ecentroevaluacion"){//captura ecentroevaluacion.uniandes.edu.co     
        current_tenant = "ecentroevaluacion"
    }else{//la configuración para ecentroespanol.uniandes.edu.co, pruebas y localhost            
        current_tenant = "ecentroespanol"
    }
    return current_tenant
}

const tenant = getTenant()

async function loadApplicationCss(){
    if(tenant === "ecentroevaluacion"){
        await import(`../assets/stylesheets/ecentroevaluacion/application.css`)
    }else{
        await import('../assets/stylesheets/ecentroespanol/application.css')
    }
}

export const current_tenant = tenant
export function tenant_in_state() {//poner aca dispatch en caso que no funcione
    loadApplicationCss()
    return({
        type: SET_TENANT,
        payload: tenant
    })
}

