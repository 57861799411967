import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from "./constants";

export const GET_ADVANCE_PAYMENT = "GET_ADVANCE_PAYMENT";
export const GET_ADVANCE_PAYMENTS = "GET_ADVANCE_PAYMENTS";
export const CREATE_ADVANCE_PAYMENT = "CREATE_ADVANCE_PAYMENT";
export const DELETE_ADVANCE_PAYMENT = "DELETE_ADVANCE_PAYMENT";
export const UPDATE_ADVANCE_PAYMENT = "UPDATE_ADVANCE_PAYMENT";

/**
 * Obtiene el anticipo solicitado.
 *
 * @export getAdvancePayment
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAdvancePayment(
  id,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/advance_payments/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_ADVANCE_PAYMENT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
/**
 * Obtiene anticipos
 *
 * @export getAdvancePayments
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAdvancePayments(
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/advance_payments`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_ADVANCE_PAYMENTS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Obtiene anticipos paginación
 *
 * @export getAdvancePaymentsPagination
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAdvancePaymentsPagination(
  pagination,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/advance_payments${pagination}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_ADVANCE_PAYMENTS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * crear un anticipo.
 *
 * @export createAdvancePayment
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createAdvancePayment(
  advance_payment,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/advance_payments/`,
    { advance_payment: advance_payment },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: CREATE_ADVANCE_PAYMENT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * borrar un anticipo.
 *
 * @export deleteAdvancePayment
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteAdvancePayment(
  id,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.delete(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/advance_payments/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(() => {
        dispatch({
          type: DELETE_ADVANCE_PAYMENT,
          payload: id,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * editar un anticipo.
 *
 * @export updateAdvancePayment
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateAdvancePayment(
  id,
  id_project,
  id_travel,
  id_expense_account,
  advance_payment,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/advance_payments/${id}`,
    { advance_payment: advance_payment },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ADVANCE_PAYMENT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
