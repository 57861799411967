import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./assets/stylesheets/editor.css";
import "./assets/stylesheets/grading-matrix.css";
import reducers from "./reducers";
import App from "./components/App";
import { LOG_IN_USER } from "./actions/authenticationActions";
import Tenant from "./components/utils/Tenant";
import { tenant_in_state } from "./actions/tenantActions";
import { PAGINATION } from "./actions/constants";
const extension = window.devToolsExtension
  ? window.devToolsExtension()
  : (f) => f;
const store = createStore(reducers, compose(applyMiddleware(thunk), extension));

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

const authenticationToken = sessionStorage.getItem("authenticationToken");
if (authenticationToken) {
  const user = {
    authenticationToken: authenticationToken,
    email: sessionStorage.getItem("email"),
    isTraveler: sessionStorage.getItem("isTraveler"),
    isAdmin: sessionStorage.getItem("isAdmin"),
    userRoles: JSON.parse(sessionStorage.getItem("userRoles")),
    preferredRole: sessionStorage.getItem("preferredRole"),
    id: sessionStorage.getItem("id"),
    pageSpendings: PAGINATION,
  };
  store.dispatch({
    type: LOG_IN_USER,
    payload: user,
  });
}

store.dispatch(tenant_in_state()); //setea el tenant en el state antes que se renderie la app

ReactDOM.render(
  <Provider store={store}>
    <>
      <Tenant />
      <App />
    </>
  </Provider>,
  document.getElementById("root")
);
