import _ from "lodash";
import {
  GET_TRAVEL,
  GET_TRAVELS,
  CREATE_TRAVEL,
  DELETE_TRAVEL,
  UPDATE_TRAVEL,
} from "../actions/travelActions";

/**
 * Manejo del estado de viaje.
 * Permite obtener un viaje
 * Permite obtener viajes.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case GET_TRAVELS:
      const travels = action.payload.travels;
      const pagination = action.payload.links;
      return { ...state, travels, pagination };
    case GET_TRAVEL:
      const travel = action.payload;
      return { ...state, travel };
    case CREATE_TRAVEL:
      const created_travel = action.payload;
      return { ...state, travel };
    case DELETE_TRAVEL:
      const deleted_travel = action.payload;
      return { ...state, travel };
    case UPDATE_TRAVEL:
      const updated_travel = action.payload;
      return { ...state, travel };
    default:
      return state;
  }
}
