import React from "react"; //se tiene que importar React para poder exportar un jsx

var Tenant_config = {
  title:
    "Plataforma de Legalizacion de Gastos - Centro de Evaluación | Uniandes",
  description: {
    content:
      "Plataforma de Legalizacion de Gastos del Centro de Evaluación de la Universidad de los Andes.  ......",
  },
  keywords: {
    content: "Uniandes, Evaluación, Centro",
  },
};

//https://stackoverflow.com/questions/59432858/react-dynamically-adding-html-elements <- cargar html dinámicamente

var Tenant_logout_info = [
  //se tiene que meter el jsx en un arreglo para que React lo renderice
  <div className="container row" key={12334}>
    <div style={{ height: "100px" }}></div>
    <h1 key="h1_logout" className="centered-text">
      Gracias por haber utilizado la plataforma de Legalizaciónn de Gastos del
      Centro de Evaluación de la Universidad de los Andes
    </h1>
    <div style={{ height: "100px" }}></div>
    <div className="row">
      <div className="thumbnail">
        <img
          src="/images/ecentroespanol/logo_CE.png"
          alt="Universidad de los Andes, Centro de Evaluación"
          width="50%"
        />
      </div>
    </div>
  </div>,
];

//en caso de tener que modificar el footer dinamicamente:
// var Tenant_footer = {
//     "paragraphs": [
//         {
//             "p_align": "textAlign:'left'",
//             "info": `Universidad de los Andes | Vigilada Mineducación
//             Reconocimiento personería jurídica: Resolución 28 del 23 de febrero de 1949 Minjusticia.
//             Cra 1 Nº 18A- 12 Bogotá, (Colombia) | Código postal: 111711 | Tels: +571 3394949 - +571 3394999`
//         },{
//             "p_align": "textAlign:'right'",
//             "info": ``
//         }
//     ]
// }

export { Tenant_config, Tenant_logout_info };
