import _ from "lodash";
import {
  GET_PROJECT,
  GET_PROJECTS,
  CREATE_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
} from "../actions/projectActions";

/**
 * Manejo del estado de proyecto.
 * Permite obtener un proyecto
 * Permite obtener proyectos.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case GET_PROJECTS:
      const projects = action.payload.projects;
      const pagination = action.payload.links;
      return { ...state, projects, pagination };
    case GET_PROJECT:
      const project = action.payload;
      return { ...state, project };
    case CREATE_PROJECT:
      const created_project = action.payload;
      return { ...state, project };
    case DELETE_PROJECT:
      const deleted_project = action.payload;
      return { ...state, project };
    case UPDATE_PROJECT:
      const updated_project = action.payload;
      return { ...state, project };
    default:
      return state;
  }
}
