import _ from "lodash";
import {
  GET_EXPENSE_ACCOUNT,
  GET_EXPENSE_ACCOUNTS,
  CREATE_EXPENSE_ACCOUNT,
  DELETE_EXPENSE_ACCOUNT,
  UPDATE_EXPENSE_ACCOUNT,
  DOWNLOAD_EXPENSE_ACCOUNT,
  GET_TOTALS,
} from "../actions/expense_accountActions";

/**
 * Manejo del estado de cuenta de gastos.
 * Permite obtener un cuenta de gastos
 * Permite obtener cuentas de gastos.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case GET_EXPENSE_ACCOUNTS:
      const expense_accounts = action.payload.expenseAccounts;
      const pagination = action.payload.links;
      return { ...state, expense_accounts, pagination };
    case GET_EXPENSE_ACCOUNT:
      const expense_account = action.payload;
      return { ...state, expense_account };
    case DOWNLOAD_EXPENSE_ACCOUNT:
      const download_expense_account = action.payload;
      return { ...state, download_expense_account };
    case CREATE_EXPENSE_ACCOUNT:
      const created_expense_account = action.payload;
      return { ...state, expense_account };
    case DELETE_EXPENSE_ACCOUNT:
      const deleted_expense_account = action.payload;
      return { ...state, expense_account };
    case UPDATE_EXPENSE_ACCOUNT:
      const updated_expense_account = action.payload;
      return { ...state, expense_account };
    case GET_TOTALS:
      return { ...state, totals_back: action.payload };
    default:
      return state;
  }
}
