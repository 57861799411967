import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from "./constants";

export const GET_SPENDING = "GET_SPENDING";
export const GET_SPENDINGS = "GET_SPENDINGS";
export const CREATE_SPENDING = "CREATE_SPENDING";
export const DELETE_SPENDING = "DELETE_SPENDING";
export const UPDATE_SPENDING = "UPDATE_SPENDING";

/**
 * Obtiene el gasto solicitado.
 *
 * @export getSpending
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getSpending(
  id,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/spendings/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_SPENDING,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
/**
 * Obtiene gastos
 *
 * @export getSpendings
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getSpendings(
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/spendings`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_SPENDINGS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Obtiene gastos paginación
 *
 * @export getSpendingsPagination
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getSpendingsPagination(
  pagination,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/spendings${pagination}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_SPENDINGS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * crear un gasto.
 *
 * @export createSpending
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createSpending(
  spending,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/spendings/`,
    { spending: spending },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: CREATE_SPENDING,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * borrar un gasto.
 *
 * @export deleteSpending
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteSpending(
  id,
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.delete(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/spendings/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(() => {
        dispatch({
          type: DELETE_SPENDING,
          payload: id,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * editar un gasto.
 *
 * @export updateSpending
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateSpending(
  id,
  id_project,
  id_travel,
  id_expense_account,
  spending,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/spendings/${id}`,
    { spending: spending },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: UPDATE_SPENDING,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
