import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from "./constants";

export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";

/**
 * Obtiene el proyecto solicitado.
 *
 * @export getProject
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getProject(
  id,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(`${ROOT_URL}/projects/${id}`, getAuthHeaders());

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_PROJECT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
/**
 * Obtiene proyectos
 *
 * @export getProjects
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getProjects(
  pagination,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects${pagination}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_PROJECTS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * crear un proyecto.
 *
 * @export createProject
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createProject(
  project,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/projects/`,
    { project: project },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: CREATE_PROJECT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * borrar un proyecto.
 *
 * @export deleteProject
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteProject(
  id,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.delete(`${ROOT_URL}/projects/${id}`, getAuthHeaders());

  return (dispatch) => {
    request
      .then(() => {
        dispatch({
          type: DELETE_PROJECT,
          payload: id,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * editar un proyecto.
 *
 * @export updateProject
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateProject(
  id,
  project,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/projects/${id}`,
    { project: project },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: UPDATE_PROJECT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
