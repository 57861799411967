import _ from "lodash";
import { GET_HISTORICALS } from "../actions/historicalActions";

/**
 * Manejo del estado del hisotrico de cambios.
 * Permite obtener hisotrico de cambios.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case GET_HISTORICALS:
      const historicals = action.payload.historicals;
      const pagination = action.payload.links;
      return { ...state, historicals, pagination };
    default:
      return state;
  }
}
