import {
  LOG_IN_USER,
  LOG_OUT_USER,
  LOG_USER_UPDATE,
} from "../actions/authenticationActions";

/**
 * Manejo de la parte del estado correspondiente al usuario actual.
 * Permite en inicio de sesión de un usuario (guardado en el estado de su información).
 * Permite cerrar la sesión de un usuario (eliminación en el estado de su información).
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case LOG_IN_USER:
      return action.payload;
    case LOG_USER_UPDATE:
      return action.payload;
    case LOG_OUT_USER:
      return null;
    default:
      return state;
  }
}
