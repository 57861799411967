import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';


/**
 * Componente para cambiar el application.css según el dominio desde el que se esté ingresando
 *
 * @export
 * @class Tenant
 * @extends {Component}
 */
class Tenant extends Component {

    constructor(props){
        super(props)
        this.state = {tenant_config : this.getTenantConfig(this.props.currentTenant)}
    } 

    getTenantConfig(currentTenant){
        if(currentTenant === "ecentroevaluacion"){
           return require(`../../assets/tenant_config/ecentroevaluacion/config.js`).Tenant_config
        }else{
            return require(`../../assets/tenant_config/ecentroespanol/config.js`).Tenant_config
        }     
    }

    render() {
        return(
        <Helmet>
            <title>{`${this.state.tenant_config.title}`}</title>
            <meta name="description" content={this.state.tenant_config.description.content}/>
            <meta name="keywords" content={this.state.tenant_config.keywords.content}/>
            <link rel="manifest" href={process.env.PUBLIC_URL+`/${this.props.currentTenant}/manifest.json`}></link>
        </Helmet>
        )  
    }
}

function mapStateToProps(state) {
    return { 
      currentTenant: state.tenant.tenant
    };
}
export default connect(mapStateToProps, null)(Tenant);
