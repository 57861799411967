import React from 'react';

/**
 * Pie de página de la aplicación
 *
 * @export
 * @returns
 */
export default function () {
  return (
    <footer>
      
          <p className="pFooter">
          Universidad de los Andes | Vigilada Mineducación <br/>
          Reconocimiento como Universidad: Decreto 1297 del 30 de mayo de 1964.<br/>
          Reconocimiento personería jurídica: Resolución 28 del 23 de febrero de 1949 Minjusticia.<br/>
          © - Derechos Reservados Universidad de los Andes
          </p>
        
    </footer>
  );
}
