import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import Loadable from "react-loadable";
import Loading from "./Loading";
import Navbar from "./Navbar";
import Footer from "./Footer";
import LogIn from "./authentication/LogIn";
import requireUser from "./hocs/requireUser";
import LogOut from "./authentication/LogOut";
import Home from "./Home";
import requireAdmin from "./hocs/requireAdmin";


const Users = Loadable({
  loader: () => import("../pages/users/UsersHomePage"),
  loading: Loading,
});
// const UserNewPage = Loadable({
//   loader: () => import("../pages/users/NewUserPage"),
//   loading: Loading,
// });
// const UserEditRoles = Loadable({
//   loader: () => import("./users/UserEditRoles"),
//   loading: Loading,
// });
// const UserUpdatePage = Loadable({
//   loader: () => import("../pages/users/UserUpdatePage"),
//   loading: Loading,
// });
const ProjectHomePage = Loadable({
  loader: () => import("../pages/projects/ProjectHomePage"),
  loading: Loading,
});
const TravelHomePage = Loadable({
  loader: () => import("../pages/travels/TravelHomePage"),
  loading: Loading,
});
const ExpenseAccountHomePage = Loadable({
  loader: () => import("../pages/expense_accounts/ExpenseAccountHomePage"),
  loading: Loading,
});
const HistoricalHomePage = Loadable({
  loader: () => import("../pages/historicals/HistoricalHomePage"),
  loading: Loading,
});

/**
 * Componente raiz de la aplicación. Permite configurar la ruta de la aplicación.
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
  }
  render() {
    return (
      <BrowserRouter>
        <div>
          <Navbar />
          <div className="container">
            <Switch>
              <Route
                path="/projects/:id_project/travels/:id_travel/expense_accounts"
                component={requireUser(ExpenseAccountHomePage)}
              />
              <Route
                path="/projects/:id_project/travels"
                component={requireUser(TravelHomePage)}
              />
              <Route
                path="/projects"
                component={requireUser(ProjectHomePage)}
              />
              <Route
                path="/historicals"
                component={requireUser(HistoricalHomePage)}
              />

              <Route path="/users/login" component={LogIn} />
              <Route path="/users/logout" component={requireUser(LogOut)} />
              {/* <Route path="/users/create" component={requireAdmin(UserNewPage)} />
              <Route path="/users/edit/:id" component={requireAdmin(UserUpdatePage)} /> */}
              
              {/* <Route
                path="/users/:id/editRoles"
                component={requireAdmin(UserEditRoles)}
              /> */}
              <Route path="/users" component={requireAdmin(Users)} />
              <Route path="/" component={Home} />
              {/*debe ir al final de todas las rutas o utilizar "exact" en caso contrario*/}
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
