import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from "./constants";

// Constantes para el manejo de usuarios en el estado.

// Actualiza los usuarios en el estado.
export const GET_USERS = "GET_USERS";

// Actualiza el usuario actual en el estado.
export const GET_USER = "GET_USER";

// Actualiza los estudiantes en el estado.
export const GET_STUDENTS = "GET_STUDENTS";

// Actualiza los viajeros en el estado.
export const GET_TRAVELERS = "GET_TRAVELERS";

//Crear usuarios
export const CREATE_USER = "CREATE_USER";

export const GET_TRAVELERS_AVAILABLE_TRAVEL = "GET_TRAVELERS_AVAILABLE_TRAVEL";

// Actualiza los revisores en el estado.
export const GET_REVIEWERS = "GET_REVIEWERS";

// Actualiza los administradores en el estado.
export const GET_ADMINS = "GET_ADMINS";

// Remueve un usuario del estado.
export const DELETE_USER = "DELETE_USER";

//Agrega roles al usuario
export const ADD_USER_ROLES = "ADD_USER_ROLES";

// Colocar rol preferido
export const ADD_PREFERRED_ROLE = "ADD_PREFERRED_ROLE";

export const GET_MESSAGE_NEWUSER = 'GET_MESSAGE_NEWUSER';

export const UPDATE_USER = 'UPDATE_USER';

/**
 * Obtiene los usuarios de la aplicación.
 *
 * @export getUsers
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getUsers(
  pagination,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(`${ROOT_URL}/users${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_USERS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Obtiene los usuarios con rol traveler.
 *
 * @export getTravelers
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTravelers(
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(`${ROOT_URL}/users/travelers`, getAuthHeaders());

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_TRAVELERS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Obtiene los usuarios con rol traveler y que no estan asociados aun en un viaje en particular.
 *
 * @export getTravelersAvailableTravel
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTravelersAvailableTravel(
  travel_id,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/users/travelers_available_travel`,
    { travel_id: travel_id },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_TRAVELERS_AVAILABLE_TRAVEL,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}


/**
 * Obtiene los usuarios con rol administrador
 *
 * @export getAdmins
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAdmins(
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(`${ROOT_URL}/users/admins`, getAuthHeaders());

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_ADMINS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Crea un usuario en el sistema con los datos dados en el parámetro user.
 *
 * @export createUser
 * @param {*} user : Información del nuevo usuario a crear
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createUser(
  user,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/users/`,
    { user: user },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: CREATE_USER,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Obtiene el resultado de la operacion de asociar docentes.
 *
 * @export createUserFromExcel
 
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function createUserFromExcel( values, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/users/create_users_from_excel`, values, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MESSAGE_NEWUSER,
        payload: data
      });
      successCallback(data);
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}



/**
 * Eliminar un usuario según el id dado por parámetro.
 *
 * @export deleteUser
 * @param {*} id : EL id del usuario.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteUser(
  id,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.delete(`${ROOT_URL}/users/${id}`, getAuthHeaders());

  return (dispatch) => {
    request
      .then(() => {
        dispatch({
          type: DELETE_USER,
          payload: id,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Obtiene el usuario solicitado.
 *
 * @export getUser
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getUser(
  id,
  successCallback = () => {},
  errorCallback = (error) => {}
  ){
    const request = axios.get(`${ROOT_URL}/users/${id}`, getAuthHeaders());

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_USER,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Agrega roles al usuario seleccionado.
 *
 * @export editUserRoles
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editUserRoles(
  id,
  roles,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/users/${id}/add_roles`,
    { roles: roles },
    getAuthHeaders()
  );
  return (dispatch) => {
    request
      .then((data) => {
        dispatch({
          type: ADD_USER_ROLES,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Colocar rol preferido
 *
 * @export editUserRoles
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function setPreferredRole(
  id,
  preferred_role,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/users/${id}/update_preferred_role`,
    { preferred_role: preferred_role },
    getAuthHeaders()
  );
  return (dispatch) => {
    request
      .then((data) => {
        dispatch({
          type: ADD_PREFERRED_ROLE,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

export function become(
  id,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/users/become`,
    { id: id },
    getAuthHeaders()
  );
  request.then(({ data }) => {
    sessionStorage.setItem("email", data.email);
    sessionStorage.setItem("authenticationToken", data.authentication_token);
    successCallback();
  });
}


/**
 * editar un usuario
 *
 * @export updateUser
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function updateUser(
  id,
  user,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/users/${id}`,
    { user: user },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: UPDATE_USER,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
