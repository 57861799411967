import _ from "lodash";
import {
  GET_TRAVELERS,
  GET_ADMINS,
  GET_TRAVELERS_AVAILABLE_TRAVEL,
  ADD_PREFERRED_ROLE,
  CREATE_USER,
  GET_MESSAGE_NEWUSER,
  GET_USERS,
  GET_USER,
  UPDATE_USER,
} from "../actions/usersActions";

/**
 * Manejo del estado de usuarios.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case GET_USERS:
      const users = action.payload.users;
      const pagination = action.payload.links;
      return { ...state, users, pagination };
    case GET_USER:
      const user = action.payload.user;
      return { ...state, user };
    case GET_TRAVELERS_AVAILABLE_TRAVEL:
      const travelers_available_travel = _.mapKeys(action.payload.users, "id");
      return { ...state, travelers_available_travel };
    case GET_TRAVELERS:
      const travelers = _.mapKeys(action.payload.users, "id");
      return { ...state, travelers };
    case ADD_PREFERRED_ROLE:
      return null;
    case GET_ADMINS:
      const admins = _.mapKeys(action.payload.admins, "id");
      // const pagination = action.payload.links;
      return { ...state, admins };
    case CREATE_USER:
      return { ...state };
    case UPDATE_USER:
      return { ...state };
    case GET_MESSAGE_NEWUSER:
      const message= action.payload.message;
      return { ...state, message: message };
    default:
      return state;
  }
}
