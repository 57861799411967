import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logOutUser } from '../../actions/authenticationActions';
import Loading from '../Loading';

/**
 * Componente que maneja el cierre de sesión y muestra información
 * luego del cierre de la misma en la aplicación.
 *
 * @class LogOut
 * @extends {Component}
 */
class LogOut extends Component {

  constructor(props){
    super(props)
    this.state = {}
    this.getTenantLogoutInfo = this.getTenantLogoutInfo.bind(this);    
    this.getTenantLogoutInfo(this.props.currentTenant)    
  }

  async getTenantLogoutInfo(tenant){
    if(tenant === "ecentroevaluacion"){
      await import(`../../assets/tenant_config/ecentroevaluacion/config.js`).then(config => {this.setState({logout_info:config.Tenant_logout_info})})       
    }else{
      await import(`../../assets/tenant_config/ecentroespanol/config.js`).then(config => {this.setState({logout_info:config.Tenant_logout_info})})       
    }   
  }

  componentDidMount() {
    this.props.logOutUser();
  }

  render() {
    if(!this.state.logout_info) {
      return (
        <Loading />
      );
    }
    return (
      <div>
        {this.state.logout_info}    
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    currentTenant: state.tenant.tenant
  };
}

export default connect(mapStateToProps, { logOutUser })(LogOut);
