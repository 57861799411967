import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";
import NavItem from "./workaround/NavItem";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { LOG_IN_URL_OUTLOOK, ROOT_URL } from "../actions/constants";
import { LOG_IN_URL_GMAIL } from "../actions/constants";
import { logUserUpdate } from "../actions/authenticationActions";
import { setPreferredRole } from "../actions/usersActions";

/**
 * Componente que permite navegar la aplicación.
 *
 * @class Navbar
 * @extends {Component}
 */
class Navbar extends Component {
  renderLoginLogout() {
    if (this.props.currentUser) {
      return <NavItem href="/users/logout">Cerrar sesión</NavItem>;
    } else {
      return (
        <NavDropdown eventKey={2} title="Ingresar" id="basic-nav-dropdown">
          <MenuItem href={LOG_IN_URL_OUTLOOK} eventKey={3}>
            Outlook(Uniandes)
          </MenuItem>
          <MenuItem href={LOG_IN_URL_GMAIL} eventKey={4}>
            Gmail
          </MenuItem>
        </NavDropdown>
      );
    }
  }

  renderHelp() {
    const url_manual_user =
      this.props.currentUser?.preferredRole != "admin"
        ? `${ROOT_URL}/manual_user/manual usuario(Viajero) app legalización de gastos.pdf`
        : `${ROOT_URL}/manual_user/manual usuario(Gestor) app legalización de gastos.pdf`;
    return (
      <>
      <NavDropdown
        eventKey={2}
        title={
          <i aria-hidden="true" className={`fa fa-question-circle fa-2x`}></i>
        }
        id="basic-nav-dropdown"
      >
        <MenuItem href={url_manual_user} target="_blank" eventKey={3}>
          Manual de Usuario
        </MenuItem>
      </NavDropdown>

      <div className="navbar-nav inactive">V2.3</div>
</>
      
    );
  }

  renderAdminNavItems() {
    if (this.props.currentUser) {
      return (
        <>
          <MenuItem href="/projects" eventKey={9}>
            Proyectos
          </MenuItem>
          {this.props.currentUser.preferredRole == "admin" ? (
            <MenuItem href="/users/" eventKey={9}>
             Usuarios
            </MenuItem>
          ) : null}
          {this.props.currentUser.preferredRole == "admin" ? (
            <MenuItem href="/historicals" eventKey={9}>
              Histórico de cambios
            </MenuItem>
          ) : null}
          
        </>
      );
    } else {
      return null;
    }
  }

  renderDataUser() {
    if (this.props.currentUser && this.props.currentUser.userRoles) {
      return (
        <>
          <NavDropdown
            title={`${this.props.currentUser.email} (${this.translate_role(
              this.props.currentUser.preferredRole
            )})`}
            id="basic-nav-dropdown"
          >
            {this.props.currentUser.userRoles.map((user_role, index) => (
              <MenuItem
                onClick={() => {
                  if (user_role.role == this.props.currentUser.preferredRole) {
                    return null;
                  }
                  this.props.logUserUpdate(
                    this.props.currentUser,
                    user_role.role
                  );
                  this.props.setPreferredRole(
                    this.props.currentUser.id,
                    user_role.role
                  );
                  this.props.history.push("/");
                }}
                key={index}
              >
                {this.translate_role(user_role.role)}
              </MenuItem>
            ))}
          </NavDropdown>
        </>
      );
    } else {
      return null;
    }
  }

  translate_role = (role) => {
    switch (role) {
      case "admin":
        return "Gestor";
      case "traveler":
        return "Viajero";
    }
  };

  render() {
    return (
      <BootstrapNavbar collapseOnSelect>
        <BootstrapNavbar.Header>
          <BootstrapNavbar.Brand>
            <Link to="/">
              <img
                src={`/images/${this.props.currentTenant}/LogoAndes.svg`}
                alt="Universidad de los Andes"
                width="165px"
              />
            </Link>
          </BootstrapNavbar.Brand>
          <BootstrapNavbar.Toggle />
        </BootstrapNavbar.Header>
        <BootstrapNavbar.Collapse>
          <Nav>{this.renderAdminNavItems()}</Nav>
          <Nav pullRight>{this.renderHelp()}</Nav>
          <Nav pullRight>{this.renderLoginLogout()}</Nav>
          <Nav pullRight>{this.renderDataUser()}</Nav>
        </BootstrapNavbar.Collapse>
      </BootstrapNavbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    currentTenant: state.tenant.tenant,
  };
}

export default connect(mapStateToProps, { logUserUpdate, setPreferredRole })(
  withRouter(Navbar)
);
