import _ from "lodash";
import {
  GET_ADVANCE_PAYMENT,
  GET_ADVANCE_PAYMENTS,
  CREATE_ADVANCE_PAYMENT,
  DELETE_ADVANCE_PAYMENT,
  UPDATE_ADVANCE_PAYMENT,
} from "../actions/advance_paymentActions";

/**
 * Manejo del estado de anticipo.
 * Permite obtener un anticipo
 * Permite obtener anitcipos.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case GET_ADVANCE_PAYMENTS:
      const advance_payments = _.mapKeys(action.payload.advancePayments, "id");
      const pagination = action.payload.links;
      return { ...state, advance_payments, pagination };
    case GET_ADVANCE_PAYMENT:
      const advance_payment = action.payload;
      return { ...state, advance_payment };
    case CREATE_ADVANCE_PAYMENT:
      const created_advance_payment = action.payload;
      state.advance_payments[created_advance_payment.advancePayments.id] =
        created_advance_payment.advancePayments;
      return { ...state };
    case DELETE_ADVANCE_PAYMENT:
      const deleted_advance_payment = action.payload;
      delete state.advance_payments[deleted_advance_payment];
      return { ...state, advance_payment };
    case UPDATE_ADVANCE_PAYMENT:
      const updated_advance_payment = action.payload;
      state.advance_payments[updated_advance_payment.advancePayments.id] =
        updated_advance_payment.advancePayments;
      return { ...state, advance_payment };
    default:
      return state;
  }
}
