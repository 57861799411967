import _ from "lodash";
import {
  GET_SPENDING,
  GET_SPENDINGS,
  CREATE_SPENDING,
  DELETE_SPENDING,
  UPDATE_SPENDING,
} from "../actions/spendingActions";

/**
 * Manejo del estado de gasto.
 * Permite obtener un gasto
 * Permite obtener gastos.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente al inicio de sesión)
 * @returns El estado con la modificación/acción realizada.
 */

export default function (state = null, action) {
  switch (action.type) {
    case GET_SPENDINGS:
      const spendings = _.mapKeys(action.payload.spendings, "id");
      const pagination = action.payload.links;
      return { ...state, spendings, pagination };
    case GET_SPENDING:
      const spending = action.payload;
      return { ...state, spending };
    case CREATE_SPENDING:
      const created_spending = action.payload;
      const last_page = created_spending.lastPage;
      state.spendings[created_spending.spendings.id] =
        created_spending.spendings;
      return { ...state, last_page };
    case DELETE_SPENDING:
      const deleted_spending = action.payload;
      delete state.spendings[deleted_spending];
      return { ...state, spending };
    case UPDATE_SPENDING:
      const updated_spending = action.payload;
      state.spendings[updated_spending.spendings.id] =
        updated_spending.spendings;
      return { ...state, spending };
    default:
      return state;
  }
}
