import axios from "axios";
import swal from "sweetalert2";
import { getAuthHeaders, ROOT_URL } from "./constants";

// Constantes para el manejo de inicio de sesión.

// Actualiza los datos del usuario del estado de la aplicación.
export const LOG_IN_USER = "LOG_IN_USER";

// Remueve los datos del usuario del estado de la aplicación.
export const LOG_OUT_USER = "LOG_OUT_USER";

export const LOG_USER_UPDATE = "LOG_USER_UPDATE";

/**
 * Autenticación del sistema manejando el token e información de inicio de sesión del usuario.
 * La información del mismo luego de autenticarse (token e email) se guarda en el LocalStorage del navegador.
 *
 * @export logInUser
 * @param {*} values : Información de inicio de sesión del usuario.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function logInUser(values, successCallback, errorCallback) {
  const request = axios.post(`${ROOT_URL}/sessions`, values);

  return (dispatch) => {
    request
      .then(({ data }) => {
        const user = getUserFromData(data);
        saveUserInfoInLocalStorage(user);
        dispatch({
          type: LOG_IN_USER,
          payload: user,
        });

        successCallback();
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
}

export function logUserUpdate(current_user, choosen_role) {
  current_user.preferredRole = choosen_role;
  const user = upateUserFromData(current_user);
  return (dispatch) => {
    saveUserInfoInLocalStorage(user);
    dispatch({
      type: LOG_USER_UPDATE,
      payload: user,
    });
  };
}

/**
 * Representación de la información de un usuario. Incluye: token de autenticación,
 * email, y role.
 *
 * @param {*} data : Información del usuario
 * @returns Información del usuarios con token de autenticación, email, y role.
 */
function getUserFromData(data) {
  const user = {
    authenticationToken: data.authenticationToken,
    email: data.email,
    userRoles: data.userRoles,
    id: data.id,
  };
  user.isTraveler = data.userRoles.some(
    (userRole) => userRole.role === "traveler"
  );
  user.isAdmin = data.userRoles.some((userRole) => userRole.role === "admin");
  /*if (!sessionStorage.getItem("preferredRole")) {
    if (user.isAdmin) {
      user.preferredRole = "admin";
    } else {
      user.preferredRole = "traveler";
    }
  } else {
    user.preferredRole = sessionStorage.getItem("preferredRole");
  }*/

  user.preferredRole = data.preferredRole;

  return user;
}

function upateUserFromData(data) {
  const user = {
    authenticationToken: data.authenticationToken,
    email: data.email,
    userRoles: data.userRoles,
    id: data.id,
  };
  user.isTraveler = data.userRoles.some(
    (userRole) => userRole.role === "traveler"
  );
  if (data.preferredRole == "admin") {
    user.isAdmin = data.userRoles.some((userRole) => userRole.role === "admin");
  } else {
    user.isAdmin = false;
  }

  user.preferredRole = data.preferredRole;
  return user;
}

/**
 * Guarda la información del usuario en el LocalStorage del navegador
 *
 * @param {*} user : Información del usuario a guardar. Incluye: token de autenticación,
 * email y role.
 */
function saveUserInfoInLocalStorage(user) {
  sessionStorage.setItem("authenticationToken", user.authenticationToken);
  sessionStorage.setItem("email", user.email);
  sessionStorage.setItem("userRoles", JSON.stringify(user.userRoles));
  sessionStorage.setItem("id", user.id);

  if (user.isTraveler) {
    sessionStorage.setItem("isTraveler", "true");
  }
  if (user.isAdmin) {
    sessionStorage.setItem("isAdmin", "true");
  }
  sessionStorage.setItem("preferredRole", user.preferredRole);
}

/**
 * Cierre de sesión del usuario con el sistema. Remueve la información del usuario del LocalStorage
 * del navegador
 *
 * @export logOutUser
 * @returns Dispatch de cierre de sesión para actualizar el estado de la interfaz
 */
export function logOutUser() {
  const request = axios.delete(`${ROOT_URL}/sessions`, getAuthHeaders());
  request.then().catch((error) => {});

  removeUserInfoFromLocalStorage();

  return { type: LOG_OUT_USER };
}

/**
 * Fuerza el cierre de la sesión. Remueve la información del usuario del LocalStorage
 * del navegador.
 *
 * @export forceLogOut
 * @param {*} dispatch : Callback de función para manejo de estado de la interfaz
 */
export function forceLogOut(dispatch) {
  removeUserInfoFromLocalStorage();

  dispatch({
    type: LOG_OUT_USER,
  });
}

/**
 * Remueve la información del usuario del LocalStorage del navegador.
 * Se remueve: token de autenticación, email, información de rol del
 * estudiante.
 *
 */
function removeUserInfoFromLocalStorage() {
  sessionStorage.removeItem("authenticationToken");
  sessionStorage.removeItem("email");
  sessionStorage.removeItem("isTraveler");
  sessionStorage.removeItem("isAdmin");
  sessionStorage.removeItem("userRoles");
  sessionStorage.removeItem("preferredRole");
}

/**
 * Manejo de expiración de sesión. Fuerza el cierre de sesión y actualiza
 * la página.
 *
 */
function confirmLogOut() {
  if (sessionStorage.getItem("authenticationToken")) {
    var timer = setTimeout(logout, 300000);
    swal({
      title: "Atención",
      text: "Su sesión está por expirar, ¿Desea continuar en esta sesión?",
      type: "warning",
      confirmButtonText: "Si, continuar",
    }).then((isConfirm) => {
      if (isConfirm) {
        swal({
          text: "Ha renovado su sesión",
          type: "success",
        });
        clearTimeout(timer);
        setTimeout(confirmLogOut, 10000000);
      }
    });
  }
}
function logout() {
  swal("Su sesión expiró");
  logOutUser();
  this.location.reload();
}

// Timer para revisar si la sesión debería expirar.
setTimeout(confirmLogOut, 10000000);

export function sendLoginError(error) {
  axios
    .post(`${ROOT_URL}/sessions/print_front_end_error`, { error: error })
    .catch((error) => {});
}
