import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from "./constants";

export const GET_EXPENSE_ACCOUNT = "GET_EXPENSE_ACCOUNT";
export const GET_EXPENSE_ACCOUNTS = "GET_EXPENSE_ACCOUNTS";
export const CREATE_EXPENSE_ACCOUNT = "CREATE_EXPENSE_ACCOUNT";
export const DELETE_EXPENSE_ACCOUNT = "DELETE_EXPENSE_ACCOUNT";
export const UPDATE_EXPENSE_ACCOUNT = "UPDATE_EXPENSE_ACCOUNT";
export const DOWNLOAD_EXPENSE_ACCOUNT = "DOWNLOAD_EXPENSE_ACCOUNT";
export const GET_TOTALS = "GET_TOTALS";

/**
 * Obtiene la cuenta de gastos solicitado.
 *
 * @export getExpenseAccount
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExpenseAccount(
  id,
  id_project,
  id_travel,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_EXPENSE_ACCOUNT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
/**
 * Obtiene cuenta de gastoss
 *
 * @export getExpenseAccounts
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getExpenseAccounts(
  pagination,
  id_project,
  id_travel,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts${pagination}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_EXPENSE_ACCOUNTS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * crear una cuenta de gastos.
 *
 * @export createExpenseAccount
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createExpenseAccount(
  expense_account,
  id_project,
  id_travel,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/`,
    { expense_account: expense_account },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: CREATE_EXPENSE_ACCOUNT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * borrar una cuenta de gastos.
 *
 * @export deleteExpenseAccount
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteExpenseAccount(
  id,
  id_project,
  id_travel,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.delete(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(() => {
        dispatch({
          type: DELETE_EXPENSE_ACCOUNT,
          payload: id,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * editar una cuenta de gastos.
 *
 * @export updateExpenseAccount
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateExpenseAccount(
  id,
  id_project,
  id_travel,
  expense_account,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id}`,
    { expense_account: expense_account },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: UPDATE_EXPENSE_ACCOUNT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Descarga formato de legalizacion de gastos.
 *
 * @export downloadExpenseAccount
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function downloadExpenseAccount(
  id,
  id_project,
  id_travel,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id}/download`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: DOWNLOAD_EXPENSE_ACCOUNT,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * Obtner totales de gastos y anticipos de una cuenta de gastos.
 *
 * @export getTotals
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTotals(
  id_project,
  id_travel,
  id_expense_account,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id_travel}/expense_accounts/${id_expense_account}/totals`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_TOTALS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
