import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from "./constants";

export const GET_TRAVEL = "GET_TRAVEL";
export const GET_TRAVELS = "GET_TRAVELS";
export const CREATE_TRAVEL = "CREATE_TRAVEL";
export const DELETE_TRAVEL = "DELETE_TRAVEL";
export const UPDATE_TRAVEL = "UPDATE_TRAVEL";

/**
 * Obtiene el viaje solicitado.
 *
 * @export getTravel
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTravel(
  id,
  id_project,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_TRAVEL,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
/**
 * Obtiene viajes
 *
 * @export getTravels
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getTravels(
  pagination,
  id_project,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.get(
    `${ROOT_URL}/projects/${id_project}/travels${pagination}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_TRAVELS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * crear un viaje.
 *
 * @export createTravel
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createTravel(
  travel,
  id_project,
  successCallback = () => {},
  errorCallback = (error) => {}
) {
  const request = axios.post(
    `${ROOT_URL}/projects/${id_project}/travels/`,
    { travel: travel },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: CREATE_TRAVEL,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * borrar un viaje.
 *
 * @export deleteTravel
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteTravel(
  id,
  id_project,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.delete(
    `${ROOT_URL}/projects/${id_project}/travels/${id}`,
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(() => {
        dispatch({
          type: DELETE_TRAVEL,
          payload: id,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}

/**
 * editar un viaje.
 *
 * @export updateTravel
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateTravel(
  id,
  id_project,
  travel,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const request = axios.patch(
    `${ROOT_URL}/projects/${id_project}/travels/${id}`,
    { travel: travel },
    getAuthHeaders()
  );

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: UPDATE_TRAVEL,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
