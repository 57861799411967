import React from 'react';//se tiene que importar React para poder exportar un jsx
var Tenant_config = {
    "title": "Plataforma de Exámenes - Centro de Evaluación | Uniandes",
    "description": {
        content: "Plataforma de exámenes del Centro de Evaluación de la Universidad de los Andes. Apoyamos el desarrollo de mediciones y evaluaciones para el mejoramiento de la calidad de la educación. "
    },
    "keywords": {        
        content: "Uniandes, Evaluación, Centro, Medición,Educación"
    },
}

var Tenant_logout_info = [//se tiene que meter el jsx en un arreglo para que React lo renderice
    <h1 key = 'h1_logout'>
        Gracias por haber utilizado la plataforma de exámenes del Centro de Evaluación de la Universidad de los Andes
    </h1>,
]

export {Tenant_config, Tenant_logout_info}

