import axios from "axios";
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from "./constants";

export const GET_HISTORICALS = "GET_HISTORICALS";

/**
 * Obtiene historico de cambios
 *
 * @export getHistoricals
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getHistoricals(
  page,
  per_page,
  id_user,
  successCallback = () => {},
  errorCallback = (error) => {},
  searching_text = "",
  order_object = "",
  order_type = ""
) {
  const pagination = `?page%5Bnumber%5D=${page}&page%5Bsize%5D=${per_page}&searching_text=${searching_text}&order%5Bcolumn%5D=${order_object}&order%5Btype%5D=${order_type}`;
  const request = axios.get(
    `${ROOT_URL}/users/${id_user}/historical${pagination}`,
    getAuthHeaders()
  );

  
  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_HISTORICALS,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
  };
}
